<template>
  <div class="login-progress">
    <van-image
      width="130"
      height="130"
      round
      fit="cover"
      :src="require('@/assets/logo.png')"
    />
    <van-loading color="#40c057" />
  </div>
</template>

<script>
import { Loading, Image as VanImage, Toast } from "vant";
import { parseProductUrl, query1688SyncResult } from "./service";
import { COMPANY } from "@/utils/constants";

export default {
  components: {
    [Loading.name]: Loading,
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      url: this.$route.query.url,
      retryTime: 0,
      maxRetryTime: 33,
      ali1688ItemId: -1,
      querySyncFinish: false,
    };
  },
  methods: {
    async parseProductUrl() {
      const payLoad = {
        productUrl: this.url,
        company: COMPANY,
      };

      const res = await parseProductUrl(payLoad);
      Toast.clear();
      const { success, data, message } = res;

      this.refreshing = false;
      if (success) {
        const { ali1688ItemId } = data;
        if (!ali1688ItemId) {
          Toast(this.$t("商品不存在"));
        } else {
          this.ali1688ItemId = ali1688ItemId;
          this.querySyncResult();
        }
      } else Toast.fail(message);
    },
    async query1688SyncResult() {
      if (!this.ali1688ItemId) return;

      const res = await query1688SyncResult(this.ali1688ItemId);
      const { success, data, message } = res;

      if (success) {
        if (!data) return;
        const { targetItemId } = data;
        Toast.clear();
        if (!this.querySyncFinish)
          this.$router.replace({
            name: "Product",
            query: { id: targetItemId },
          });
        this.querySyncFinish = true;
      } else Toast.fail(message);
    },
    querySyncResult() {
      this.retryTime = 0;
      this.querySyncFinish = false;

      this.query1688SyncResult();
      this.interval = setInterval(() => {
        this.retryTime++;
        if (this.querySyncFinish) {
          clearInterval(this.interval);
        }
        if (this.retryTime >= this.maxRetryTime) {
          Toast.fail(this.$t("Sync failed"));
          clearInterval(this.interval);
        } else this.query1688SyncResult();
      }, 3000);
    },
  },
  mounted() {
    this.parseProductUrl();
  },
};
</script>

<style lang="less" scoped>
.login-progress {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
</style>