import axios from '@/utils/request';

export async function parseProductUrl(payload) {
    return axios.post('/product/parseProductUrl', payload);
}

export async function sync1688ToShopify(ali1688ItemId, payload) {
    return axios.post(`/product/sync1688ToShopify/${ali1688ItemId}`, payload);
}

export async function query1688SyncResult(ali1688ItemId) {
    return axios.get(`/product/query1688SyncResult/${ali1688ItemId}`);
}